<div class="row">
  <div class="col-12">
    <ul class="breadcrumb" [ngStyle]="{'margin': breadcrumbMargin || '2rem 0'}">
      <li class="breadcrumb-item bcrumb-1">
        <a [routerLink]="routingUrl">
          <i-feather name="home" class="breadcrumb-icon"></i-feather>
        </a>
      </li>
      <li class="breadcrumb-item" *ngFor="let item of items">{{item}}</li>
      <li class="breadcrumb-item active">{{active_item}}</li>
    </ul>
  </div>
</div>
